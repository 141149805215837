@import url(https://fonts.googleapis.com/css?family=Open+Sans:300);
@import url(https://fonts.googleapis.com/css?family=Roboto:300);
@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:100);
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  height: 100%;
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
  height: 100% !important;
}

a {
  text-decoration: none;
}
.welcomeDiv {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 500;
  display: flex;
  justify-content: center;
  opacity: 0;
  align-items: center;
  background-color: #000000;
}
.welcomeInDiv {
  width: 250px;
  margin-top: 90px;
  height: 250px;
  text-align: center;
}

.welcomeH {
  pointer-events: all;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: white;
  font-size: 25px;
}
.welcomeHe {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: 25px;
  color: black;
}
.welcomeH:hover ~ .welcomeHe {
  color: rgb(177, 177, 177);
}
.subtitleDiv {
  position: absolute;
  margin-top: -19%;
  display: flex;
  flex-direction: column;
  color: white;
  font-weight: 100;
  padding-left: 5px;
  border-left: solid 1px white;
  margin-left: -400px;
  left: 50%;
  z-index: 300;
  pointer-events: none;
}
.subtitleMain {
  font-size: 30px;
  margin: 0px;
}
.subtitleSpecs {
  font-size: 15px;

  margin: 0px;
}
.subtitleDes {
  font-size: 15px;
  margin: 0px;
  padding-bottom: 4px;
  margin-left: -5px;
  padding-left: 5px;
  border-bottom: solid 1px white;
}
.subtitleOrg {
  font-size: 15px;
  margin: 0px;
}
.goImg {
  width: 90%;
  padding: 0px 5%;
  padding-top: 12px;
}

.bottMenuLink {
  text-decoration: none;
  padding: 0px;
  margin: 0px;
  color: white;
}
.popupBackDiv {
  position: fixed;
  margin: 0px;
  padding: 0px;
  top: 0px;
  left: 0px;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popupOverlay {
  position: absolute;

  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
  pointer-events: all;
}
.popDiv {
  background: white;
  width: 600px;
  height: 800;
  z-index: 103;
  text-align: center;
}

.popupImgDiv {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 45px;
  width: 550px;
  height: 520px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.absolCap {
  position: absolute;

  left: 50%;
  margin-left: -65px;
  top: 50%;
  margin-top: -10px;
  z-index: -1;
}
.imgPH {
  max-height: 550px;
}
.popupLinkImg {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.popupLinkImg:hover {
  opacity: 0.7;
}

.popopCaption i {
  margin: 0px;
  padding: 0px;
  height: 85px;
  font-size: 18px;
  font-family: "Permanent Marker", cursive;

  display: flex;
  align-items: center;
  justify-content: center;
}
.menuOut {
  position: absolute;
  width: 96%;
  bottom: 10px;
  left: 2%;
  right: 2%;
}
.menuIn {
  position: relative;
  color: white;
  z-index: 100;
  width: 100%;
}
li {
  pointer-events: none;
  position: relative;
  width: 100%;
  text-decoration: none;
  list-style-type: none;
}
li .menuLi {
  display: flex;
  flex-direction: column;
}
.menuText {
  position: relative;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 55px;
  cursor: pointer;
  pointer-events: all;
  top: 1px;
}

.borderStrike {
  position: absolute;
  width: 100%;
  border-top: 10px solid white;
  left: 0;
  top: 50%;
  margin-top: -5px;
}
.aboutDiv {
  width: 100%;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.linkK {
  color: black;
  border-bottom: 2px solid #ec70dc;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}
.linkK:hover {
  color: #ec70dc;
}
.quad {
  width: 24%;
  margin: 0px 0.5%;
  margin-bottom: 20px;
}
.img {
  width: 100%;
}
.bottomBord {
  border-bottom: 1px solid #2c3e50;
  margin: 0px 2%;
}
.subtitle {
  margin-top: -15px;
  font-size: 12px;
}
.titleL {
  margin-bottom: -1px;
}
.titleX {
  margin-bottom: -7px;
}
canvas {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -3;
}
.navArrs {
  position: absolute;
  top: 75%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navArrsRow {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 60px;
}
.navArrsImg {
  width: 30px;
  height: 30px;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
}
.navArrsImg:hover {
  background: rgb(159, 201, 226);
}
.displayVidOut {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  position: absolute;
  -webkit-transition: all 10s;
  transition: all 10s;
}

.logoDiv {
  position: absolute;
  width: 100%;
  bottom: 10px;
  z-index: 20;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.logoImg {
  position: relative;
  width: 20px;
  padding: 7px;
  -webkit-transition: all 1s;
  transition: all 1s;
  cursor: pointer;
}
.logoImg:hover {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
.displayVid {
  pointer-events: none;
  position: absolute;
  width: 700px;
  display: flex;
  justify-items: center;
}
video {
  z-index: 43;
  width: 100%;
  pointer-events: all;
  position: absolute;
  align-self: center;
}
.vidColor1 {
  /* filter: hue-rotate(190deg); */
  -webkit-transition: all 1s;
  transition: all 1s;
}
.vidColor2 {
  /* filter: hue-rotate(250deg); */
  -webkit-transition: all 1s;
  transition: all 1s;
}
.vidColor3 {
  /* filter: hue-rotate(310deg); */
  -webkit-transition: all 1s;
  transition: all 1s;
}
.vidColor1:hover {
  -webkit-filter: hue-rotate(190deg);
          filter: hue-rotate(190deg);
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}
.vidColor2:hover {
  -webkit-filter: hue-rotate(250deg);
          filter: hue-rotate(250deg);
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}
.vidColor3:hover {
  -webkit-filter: hue-rotate(310deg);
          filter: hue-rotate(310deg);
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}
.center {
  text-align: center;
}

.mediumSize {
  font-size: 13px;
}

.popOutDiv {
  width: 80% !important;
}

.hover {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
}
.dayDiv {
  padding-bottom: 40px;
}
.menuBike {
  margin: 0px;
  margin-bottom: 2px;
  font-size: 11px;
  cursor: pointer;
}
.hoverMen:hover {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
  color: #f7797d;
}

.botMenu {
  position: relative;
  margin: 0px;
  opacity: 1;
  width: 90%;

  margin-left: 5%;
  margin-right: 5%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  padding: 10px 0px;

  /* text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center; */
}

.colStat {
  width: 23%;
  margin-left: 2%;
}
.colBack {
  width: 50%;
  margin-right: 0%;
}
.filmDiv {
  width: 23%;
  margin-right: 2%;
  display: flex;
  align-items: center;
}

.outerBikeDiv {
  margin: 0px 3%;
}
.row {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.hover:hover {
  opacity: 0.8;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.landing {
  width: 100%;
  height: 100vh;
  z-index: 10;
  position: absolute;
  background: transparent;
}
.smallFont {
  font-size: 13px;
}
.landLeft {
  /* background: transparent; */
  background: #c6ffdd;
  background: -webkit-linear-gradient(to left, #f7797d, #fbd786, #c6ffdd);
  background: -webkit-gradient(linear, right top, left top, from(#f7797d), color-stop(#fbd786), to(#c6ffdd));
  background: -webkit-linear-gradient(right, #f7797d, #fbd786, #c6ffdd);
  background: linear-gradient(to left, #f7797d, #fbd786, #c6ffdd);
}
.landRight {
  background: #bdc3c7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #2c3e50,
    #bdc3c7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    right top, left top,
    from(#2c3e50),
    to(#bdc3c7)
  );
  background: -webkit-linear-gradient(
    right,
    #2c3e50,
    #bdc3c7
  );
  background: linear-gradient(
    to left,
    #2c3e50,
    #bdc3c7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.landLeft,
.landRight {
  width: 50%;
  height: 100%;
  opacity: 1;
}
.bikeDiv {
  position: fixed;
  left: 50%;
  margin-left: -9px;
  z-index: 500;
  margin-top: 3vh;
}
.aboutDiv {
  position: fixed;
  left: 50%;
  margin-left: -9px;
  z-index: 500;
  margin-top: 95vh;
}
.aboutIcon,
.bikeIcon {
  width: 20px;
  pointer-events: all;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.aboutIcon:hover,
.bikeIcon:hover {
  opacity: 0.6;
}
.titleTextDiv {
  background: transparent;
  z-index: 100;
  width: 100%;
  height: 100vh;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.titleTextDiv:hover {
  opacity: 0.8;
  background: rgba(255, 255, 255, 0.163);
}

/*Display Page Div Styling*/
.photoDiv {
  padding: 0px;
  display: inline-flex;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 0px;
}

.tri {
  display: flex;

  margin-left: 0.5%;
  margin-right: 0.5%;
  flex-basis: 32.3%;
  flex-direction: column;
}
.topBar {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.returnDiv {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

/* text */
.textDiv {
  padding: 0px;
  display: inline-flex;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 0px;
}
.mmTex {
  width: 22%;
  margin: 0px 1%;
}
.mmTexIn {
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  color: #ffffff;
}
.museText {
}
.dateText {
  font-size: 9px;
  margin-top: 0px;
}
.museDiv {
  width: 100%;
  background: #383838;
}
.inMuseDiv {
  width: 100%;
  padding: 4px 0px;
  border-bottom: 1.5px solid rgba(155, 155, 155, 0.687);
}

.originalTex,
.inspireTex {
  width: 41%;
  margin-left: 1%;
}
.textRowDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.textSquare {
  width: 100%;
  position: relative;
  text-align: center;
  background: #383838;
  margin-bottom: 3%;
}
.textSquare:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.textSquareIn {
  position: absolute;
  color: #ffffff;
  text-align: center;
  width: 94%;
  margin: 0px 3%;
}
.textTileText {
  margin-top: 45%;
  position: relative;
  text-align: center;
}
.textCol {
  width: 48%;
  margin: 0px 1%;
}

.texIn {
  width: 100%;

  margin-top: 20px;
}

/*Text Styling*/
.pageTitle {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 2px;
}
.titleText {
  text-align: center;
  padding-top: 45vh;
  margin-top: 0px;
  color: #ffffff;
}

.linkStyle {
  text-decoration: none;
}
.linky:hover {
  opacity: 0.6;
}
.centerH {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*Icon/Photo Styling*/
.menuIcon {
  max-width: 25px;
  padding-left: 4px;
  padding-right: 4px;
}
.menuIcon:hover {
  opacity: 0.8;
}
.returnIcon {
  max-width: 15px;
  text-align: center;
}
.returnIcon:hover {
  opacity: 0.8;
}
.yt {
  padding-top: 3%;
}
.photo {
  width: 100%;
  margin: 0px;
  padding: 4px 0px;
  -webkit-transition: transform 2s; /* For Safari 3.1 to 6.0 */
  -webkit-transition: -webkit-transform 2s;
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}

.dialogStyles {
  background-color: #ffffff;
  height: 45vh;
  text-align: center;
  width: 80%;
}
.fade {
  z-index: 100;
  -webkit-animation: fadein 9s;
  animation: fadein 9s;
  margin: 0px;
}
.fadeo {
  -webkit-animation: fadeout 3s;
  animation: fadeout 3s;
  margin: 0px;
}
/* margin-left: -42vw; */
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Internet Explorer */

/* Opera < 12.1 */
@keyframes fadein {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Internet Explorer */

/* Opera < 12.1 */
@media screen and (max-width: 800px) {
  .displayVid {
    width: 90%;
  }
  .menuOut {
    bottom: 5%;
  }

  .borderStrike {
    border-top: 2px solid white;
    margin-top: -1px;
  }

  li .menuLi {
    display: flex;
    justify-content: center;
  }
  .menuText {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 36px;
  }
  .navArrs {
    top: 70%;
  }
  .subtitleDiv {
    margin-top: -45%;
    margin-left: -50%;
  }
  .subtitleDes,
  .subtitleOrg,
  .subtitleSpecs {
    font-size: 10px;
  }
}
@media screen and (max-width: 660px) {
  .subtitleDiv {
    margin-top: -55%;
  }
  .popopCaption i {
    margin-top: -30px;
    padding: 0px;
    height: 85px;
    font-size: 15px;
  }
  .popDiv {
    background: white;
    width: 95%;
    height: 60%;
    z-index: 103;
    text-align: center;
  }
  .popupImgDiv {
    margin: 0px;
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .imgPH {
    max-height: 300px;
  }
  .colStat,
  .colBack,
  .filmDiv {
    width: 100%;
    margin: 1%;
    padding: 0px;
  }
  .subtitleMain {
    font-size: 25px;
  }
  .goImg {
    padding: 0px;
    width: 70%;
  }
  .aboutDiv {
    margin-top: 5vh;
    left: 95%;
  }
  .bikeDiv {
    margin-top: 5vh;
    left: 5%;
  }

  .landLeft,
  .landRight,
  .quad {
    width: 100%;
  }
  .photoDiv {
    display: inline-block;
  }
  .tri,
  .photo {
    width: 100%;
    margin: 0px;
    padding: 4px 0px;
  }

  .textDiv {
    flex-direction: column-reverse;
  }
  .mmTex {
    width: 100%;
  }
  .inspireTex,
  .originalTex,
  .mmTex {
    width: 100%;
  }
}
@media screen and (max-width: 460px) {
  .photoF,
  .goImg {
    width: 100%;
  }
}

@viewport {
  width: device-width;
  zoom: 1;
}

@-ms-viewport {
  width: device-width;
}
.aboutPageBack {
  /* background-image: url("../img/flex.png");
  background-attachment: fixed;
  background-size: 300px; */
  background: #ffffff;
  margin-top: 0px;
  padding-bottom: 200px;
  height: 100%;
}

.aboutMain {
  pointer-events: all;
  margin-top: 0px;
}
.aboutDivOut {
  pointer-events: none;
  position: fixed;
  margin-top: -60px;
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutDivIn {
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 100;
}
.abouth1 {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}
.aboutText {
  margin: 0px;
  padding: 0px;
  font-size: 40px;
  color: black;
}
.aboutPhoto {
  width: 100%;
}
.returnDivAbout {
  width: 100%;
  text-align: center;
  padding-top: 40px;
}
.returnIconAbout {
  width: 40px;
}
.returnIconAbout:hover {
  opacity: 0.5;
}
.aboutLink {
  color: black;
  text-decoration: none;
  font-size: 25px;
  padding: 3px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.aboutLink:hover {
  background: rgb(105, 178, 204);
  color: white;
  opacity: 0.7;
}
@media screen and (max-width: 760px) {
  .aboutMain {
    margin: 0px;
  }
  .aboutText,
  .aboutLink {
    font-size: 10px;
  }
}

@viewport {
  width: device-width;
  zoom: 1;
}

@-ms-viewport {
  width: device-width;
}
.header {
  background-color: transparent;
  opacity: 1;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 40;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  pointer-events: none;
}

.header::after {
  content: "";
  display: table;
  clear: both;
}

.menuImg {
  position: fixed;
  right: 1.5%;
  top: 1.5%;
  pointer-events: auto;
}
@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateX(-2%);
            transform: translateX(-2%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideIn {
  0% {
    -webkit-transform: translateX(-2%);
            transform: translateX(-2%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

.itemContainer {
  margin: 126px 20px;
  border-top: 1px solid white;
}
.contt {
  display: flex;
  align-content: center;
}
.menuItem {
  color: white;
  padding: 20px;

  border-bottom: 1px solid white;
  text-align: center;
}
.menuItem:hover {
  opacity: 0.7;
  background: rgba(90, 89, 133, 0.422);
}
.whiteLink {
  color: #ffffff;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .logo {
    padding-left: 15px;
  }
  .menuImg {
    right: 0.5%;
  }
}
/* @media (max-width: 500px) {
  .logo {
    padding-left: 15px;
  }
  .menuImg {
    right: 0.5%;
  }
} */

