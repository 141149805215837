@viewport {
  width: device-width;
  zoom: 1;
}

@-ms-viewport {
  width: device-width;
}
@import url("https://fonts.googleapis.com/css?family=Roboto:100");
.aboutPageBack {
  /* background-image: url("../img/flex.png");
  background-attachment: fixed;
  background-size: 300px; */
  background: #ffffff;
  margin-top: 0px;
  padding-bottom: 200px;
  height: 100%;
}

.aboutMain {
  pointer-events: all;
  margin-top: 0px;
}
.aboutDivOut {
  pointer-events: none;
  position: fixed;
  margin-top: -60px;
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutDivIn {
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 100;
}
.abouth1 {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}
.aboutText {
  margin: 0px;
  padding: 0px;
  font-size: 40px;
  color: black;
}
.aboutPhoto {
  width: 100%;
}
.returnDivAbout {
  width: 100%;
  text-align: center;
  padding-top: 40px;
}
.returnIconAbout {
  width: 40px;
}
.returnIconAbout:hover {
  opacity: 0.5;
}
.aboutLink {
  color: black;
  text-decoration: none;
  font-size: 25px;
  padding: 3px;
  transition: all 0.4s;
}
.aboutLink:hover {
  background: rgb(105, 178, 204);
  color: white;
  opacity: 0.7;
}
@media screen and (max-width: 760px) {
  .aboutMain {
    margin: 0px;
  }
  .aboutText,
  .aboutLink {
    font-size: 10px;
  }
}
